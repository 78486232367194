.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse))
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 200, 48, var(--tw-bg-opacity))
}

.bg-brandYellow {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 200, 96, var(--tw-bg-opacity))
}

.bg-brandPurple {
  --tw-bg-opacity: 1;
  background-color: rgba(128, 3, 79, var(--tw-bg-opacity))
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.bg-opacity-75 {
  --tw-bg-opacity: 0.75
}

.rounded {
  border-radius: 0.25rem
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-full {
  border-radius: 9999px
}

.border-2 {
  border-width: 2px
}

.border {
  border-width: 1px
}

.border-t {
  border-top-width: 1px
}

.border-r {
  border-right-width: 1px
}

.border-b {
  border-bottom-width: 1px
}

.cursor-pointer {
  cursor: pointer
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-center {
  align-items: center
}

.content-start {
  align-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.flex-1 {
  flex: 1 1 0%
}

.flex-auto {
  flex: 1 1 auto
}

.flex-shrink-0 {
  flex-shrink: 0
}

.float-right {
  float: right
}

.font-sans {
  font-family: DM Sans, sans-serif
}

.font-medium {
  font-weight: 500
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.h-0 {
  height: 0px
}

.h-2 {
  height: 0.5rem
}

.h-4 {
  height: 1rem
}

.h-5 {
  height: 1.25rem
}

.h-6 {
  height: 1.5rem
}

.h-10 {
  height: 2.5rem
}

.h-16 {
  height: 4rem
}

.h-auto {
  height: auto
}

.h-2\.5 {
  height: 0.625rem
}

.h-screen {
  height: 100vh
}

.text-3xl {
  font-size: 50px
}

.text-xl {
  font-size: 24px
}

.text-lg {
  font-size: 22px
}

.text-base {
  font-size: 19px
}

.text-sm {
  font-size: 16px
}

.text-xs {
  font-size: 12px
}

.leading-5 {
  line-height: 1.25rem
}

.leading-6 {
  line-height: 1.5rem
}

.m-2 {
  margin: 0.5rem
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.-my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem
}

.-my-1\.5 {
  margin-top: -0.375rem;
  margin-bottom: -0.375rem
}

.mt-1 {
  margin-top: 0.25rem
}

.mb-1 {
  margin-bottom: 0.25rem
}

.ml-1 {
  margin-left: 0.25rem
}

.mt-2 {
  margin-top: 0.5rem
}

.mr-2 {
  margin-right: 0.5rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.ml-2 {
  margin-left: 0.5rem
}

.mt-3 {
  margin-top: 0.75rem
}

.ml-3 {
  margin-left: 0.75rem
}

.mt-4 {
  margin-top: 1rem
}

.mb-4 {
  margin-bottom: 1rem
}

.ml-4 {
  margin-left: 1rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mt-6 {
  margin-top: 1.5rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.mt-8 {
  margin-top: 2rem
}

.mb-8 {
  margin-bottom: 2rem
}

.-mr-12 {
  margin-right: -3rem
}

.max-w-0 {
  max-width: 0rem
}

.max-w-xs {
  max-width: 20rem
}

.max-w-2xl {
  max-width: 42rem
}

.max-w-7xl {
  max-width: 80rem
}

.min-h-screen {
  min-height: 100vh
}

.min-w-0 {
  min-width: 0px
}

.min-w-full {
  min-width: 100%
}

.object-contain {
  object-fit: contain
}

.focus-within\:outline-none:focus-within {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.overflow-hidden {
  overflow: hidden
}

.overflow-y-auto {
  overflow-y: auto
}

.p-2 {
  padding: 0.5rem
}

.p-4 {
  padding: 1rem
}

.p-5 {
  padding: 1.25rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem
}

.pb-0 {
  padding-bottom: 0px
}

.pt-2 {
  padding-top: 0.5rem
}

.pl-3 {
  padding-left: 0.75rem
}

.pr-4 {
  padding-right: 1rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pt-5 {
  padding-top: 1.25rem
}

.pr-6 {
  padding-right: 1.5rem
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px
}

.top-0 {
  top: 0px
}

.right-0 {
  right: 0px
}

* {
  --tw-shadow: 0 0 #0000
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.group:hover .group-hover\:shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

.focus-within\:ring-2:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset
}

.focus-within\:ring-offset-2:focus-within {
  --tw-ring-offset-width: 2px
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-green {
  --tw-text-opacity: 1;
  color: rgba(253, 200, 48, var(--tw-text-opacity))
}

.text-brandYellow {
  --tw-text-opacity: 1;
  color: rgba(253, 200, 96, var(--tw-text-opacity))
}

.text-brandPurple {
  --tw-text-opacity: 1;
  color: rgba(128, 3, 79, var(--tw-text-opacity))
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.text-dark {
  --tw-text-opacity: 1;
  color: rgba(17, 17, 17, var(--tw-text-opacity))
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.uppercase {
  text-transform: uppercase
}

.underline {
  text-decoration: underline
}

.tracking-normal {
  letter-spacing: 0em
}

.tracking-wider {
  letter-spacing: 0.05em
}

.align-middle {
  vertical-align: middle
}

.whitespace-nowrap {
  white-space: nowrap
}

.w-0 {
  width: 0px
}

.w-2 {
  width: 0.5rem
}

.w-4 {
  width: 1rem
}

.w-5 {
  width: 1.25rem
}

.w-6 {
  width: 1.5rem
}

.w-10 {
  width: 2.5rem
}

.w-14 {
  width: 3.5rem
}

.w-16 {
  width: 4rem
}

.w-40 {
  width: 10rem
}

.w-64 {
  width: 16rem
}

.w-auto {
  width: auto
}

.w-2\.5 {
  width: 0.625rem
}

.w-full {
  width: 100%
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-40 {
  z-index: 40
}

.gap-4 {
  gap: 1rem
}

.gap-6 {
  gap: 1.5rem
}

.gap-x-4 {
  -webkit-column-gap: 1rem;
          column-gap: 1rem
}

.gap-y-6 {
  row-gap: 1.5rem
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr))
}

.col-span-2 {
  grid-column: span 2 / span 2
}

.col-span-4 {
  grid-column: span 4 / span 4
}

.col-span-8 {
  grid-column: span 8 / span 8
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.duration-500 {
  transition-duration: 500ms
}

@keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@media (min-width: 640px) {
  .sm\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
  }

  .sm\:rounded-md {
    border-radius: 0.375rem
  }

  .sm\:rounded-lg {
    border-radius: 0.5rem
  }

  .sm\:block {
    display: block
  }

  .sm\:flex {
    display: flex
  }

  .sm\:grid {
    display: grid
  }

  .sm\:items-center {
    align-items: center
  }

  .sm\:justify-start {
    justify-content: flex-start
  }

  .sm\:justify-between {
    justify-content: space-between
  }

  .sm\:text-sm {
    font-size: 16px
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .sm\:mt-0 {
    margin-top: 0px
  }

  .sm\:ml-4 {
    margin-left: 1rem
  }

  .sm\:max-w-md {
    max-width: 28rem
  }

  .sm\:overflow-hidden {
    overflow: hidden
  }

  .sm\:p-0 {
    padding: 0px
  }

  .sm\:p-6 {
    padding: 1.5rem
  }

  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .sm\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem
  }

  .sm\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .sm\:w-full {
    width: 100%
  }

  .sm\:gap-4 {
    gap: 1rem
  }

  .sm\:gap-x-8 {
    -webkit-column-gap: 2rem;
            column-gap: 2rem
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4
  }
}

@media (min-width: 768px) {
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1
  }
}

@media (min-width: 1024px) {
  .lg\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .lg\:flex {
    display: flex
  }

  .lg\:hidden {
    display: none
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0
  }

  .lg\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .lg\:pl-2 {
    padding-left: 0.5rem
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2
  }
}

@media (min-width: 1280px) {
  .xl\:p-12 {
    padding: 3rem
  }
}

@media (min-width: 1536px) {
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

